import { faArrowRight, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Blackhorse from "../../static/image/blackhorse.jpg";
import RoyalShoes from "../../static/image/royal.jpg";
import Caliber from "../../static/image/caliber.png";
import Chito from "../../static/image/chito.jpeg";
import ViewResort from "../../static/image/360.jpeg";
import Safal from "../../static/image/safal.jpeg";
import Peace from "../../static/image/peace.png";
import Dream from "../../static/image/dream.webp";
import NGO from "../../static/image/ngologo.png";
import Janak from "../../static/image/janak.jpg";
import Durbar from "../../static/image/durbar.png";
import OldHouse from "../../static/image/oldhouse.jpeg";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";



const OurPortfolio = ()=>{
    return(
        <Box>
            <Header/>
            <Our_Portfolio_Page/>
            <Footer/>
        </Box>
    )
}

const Our_Portfolio_Page = ()=>{
    const history = useNavigate();

    const Career = ()=>{
        history('/career')
    }
    return(
        <Box>
            <Box className="our-team-images">
                <Box className="our-team-background-color">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid md={5} xs={12} item>
                                <Box className="our-team-text-margin">
                                    <Typography className="our-team-text header-fonts">Our Portfolio</Typography>
                                    {/* <Typography className="our-team-small-text body-fonts">We have 6 years of Ruby On Rails development and consulting experiences. We have 6 years of Ruby On Rails development and consulting experiences.</Typography> */}
                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Join Our Team <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box>
                <Container>
                    <Box className="my-client-margin">
                        <Grid container spacing={3}>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Blackhorse} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Black Horse Shoes</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={RoyalShoes} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Royal Shoes</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Caliber} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Caliber Shoes</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Chito} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Chito Logistics</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={ViewResort} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">View 360 Resort</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Safal} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Safal Valley View Resort</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Peace} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Kankali Peace Bamboo Resort</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Dream} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Dream Carrier Treks & Expedition</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={NGO} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Dream Carrier Nepal</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={OldHouse} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Old House Home Stay</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Durbar} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Durbar Mart</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                            <Grid item md={3} xs={12} sm={6}>
                                <Box className="card-portfolio">
                                    <Box className="image">
                                        <img src={Janak} alt="card-images"/>
                                    </Box>
                                    <Box className="details">
                                        <Box className="center">
                                            <h1 className="header-fonts">Astro Janak</h1>
                                            {/* <p className="fs-12">Black Horse Shoes was established in 2071 B.S. with the new products, technology and customer service to expand the business into the market.</p> */}
                                            <ul>
                                                <li><a href="#"><FontAwesomeIcon icon={faFacebookF} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faInstagram} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faYoutube} style={{fontSize:'13px'}}/></a></li>
                                                <li><a href="#"><FontAwesomeIcon icon={faEye} style={{fontSize:'13px'}}/></a></li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box> 
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

        </Box>
    )
}

export default OurPortfolio;