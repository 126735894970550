import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import Mobile_app from "../../static/image/mobile_app.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React_native from '../../static/image/logo192.png';
import Flutter from '../../static/image/flutter.svg';
import Android from '../../static/image/android.png';
import Python from "../../static/image/python.png";
import Node_js from "../../static/image/nodejs.png";
import Postgre from "../../static/image/postgre.png";
import Mongo from "../../static/image/mongodb.svg";
import API from "../../static/image/api.png";
import Swift from "../../static/image/swift.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const Mobile_development = ()=>{
    return(
        <Box>
            <Header/>
            <Mobile_development_page/>
            <Footer/>
        </Box>
    )
}

const Mobile_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={3} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid xs={12} md={5} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development header-fonts">Mobile App<span className="mobile-app-development1"> Development</span></Typography>
                            <Typography className="mobile-app-text body-fonts">With increased use of handheld portable devices, businesses have been exploring this unique opportunity to reach the wider customer. We develop cutting edge mobile application, suitable for both android and iOS to fulfill this need.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item xs={7} className="res-display">
                        <img src={Mobile_app} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">The smartphone Apps have changed the definition of every day activity in human life. It has been an essential part of our lives and why not? We do almost everything with the help of an app. The world is connected with mobile apps. In this really techie era, Hyperlink Infosystem can help you to craft some excellent mobile application for your business & start-ups. We are the leading tailor made mobile app development company, serving finest apps across the globe.</Typography>
                        <Typography className="web-design-css body-fonts">We have top mobile app developers who are highly skilled and updated with the latest technology trend. Our team works with the global firms to transform recognized ideas into stunning & inventive mobile Apps. We have rich experience and creativity in producing stunning mobile apps for different clients over the world.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Technology That We Use on Web Development</Typography>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin12 header-fonts">App Frontend</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Android} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Android</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Swift} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Swift</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={React_native} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">React Native</Typography>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={JS} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1">React Native</Typography>
                                        </Box>
                                    </Grid> */}
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Flutter} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Flutter</Typography>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Css3} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1" >Css3</Typography>
                                        </Box>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin header-fonts">App Backend</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Python} className="new-image-width" alt="python-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Python (Django)</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4}  style={{margin:'0 auto'}}>
                                                <img src={Node_js} className="new-image-width" alt="node-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Node JS (Express Js)</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Postgre} className="new-image-width" alt="postgre-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">PostgreSQL</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Mongo} className="new-image-width" alt="mongo-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Mongo DB</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={API} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Rest API</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Mobile_development;