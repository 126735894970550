import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";


const Step_component =()=>{
    return(
        <Box>
            <Box className="new-body-text">
                <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Steps <span>that We Follow</span></Typography>
            </Box>
            <Box className="my-client-margin">
                <Grid container spacing={3}>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box className="card-design-services1">
                            <Grid xs={3} item style={{margin:'0 auto'}}>
                                <Box>
                                    <img src={Requirement} className="new-image-width" alt="requirement-gathering"/>
                                </Box>
                            </Grid>
                            <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">1. Gathering Requirement</Typography>
                            <Typography className="steps-card-texts body-fonts">We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className="card-design-services1">
                            <Grid xs={3} item style={{margin:'0 auto'}}>
                                <Box>
                                    <img src={UI_UX} className="new-image-width" alt="requirement-gathering"/>
                                </Box>
                            </Grid>
                            <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">2. UI/UX Design</Typography>
                            <Typography className="steps-card-texts body-fonts">We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box className="card-design-services1">
                            <Grid xs={3} item style={{margin:'0 auto'}}>
                                <Box>
                                    <img src={Prototype} className="new-image-width" alt="requirement-gathering"/>
                                </Box>
                            </Grid>
                            <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">3. Prototype</Typography>
                            <Typography className="steps-card-texts body-fonts">After designing, you will get your prototype, which will be sent ahead for the development process for the product.</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <Box className="card-design-services1">
                            <Grid xs={3} item style={{margin:'0 auto'}}>
                                <Box>
                                    <img src={Web_development} className="new-image-width" alt="requirement-gathering"/>
                                </Box>
                            </Grid>
                            <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">4. Development</Typography>
                            <Typography className="steps-card-texts body-fonts">Development of mobile application/web/blockchain started using latest tools and technologies with transparency.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="my-client-margin12">
                <Grid md={9} xs={12} item style={{margin:'0 auto'}}>
                    <Grid container spacing={3}>
                        <Grid item md={4} sm={6} xs={12}>
                            <Box className="card-design-services1">
                                <Grid xs={3} item style={{margin:'0 auto'}}>
                                    <Box>
                                        <img src={Quality} className="new-image-width" alt="requirement-gathering"/>
                                    </Box>
                                </Grid>
                                <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">5. Quality Assurance</Typography>
                                <Typography className="steps-card-texts body-fonts">Hyperlink values quality and provides 100% bug free application with no compromisation in it.</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Box className="card-design-services1">
                                <Grid xs={3} item style={{margin:'0 auto'}}>
                                    <Box>
                                        <img src={Deployment} className="new-image-width" alt="requirement-gathering"/>
                                    </Box>
                                </Grid>
                                <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">6. Deployment</Typography>
                                <Typography className="steps-card-texts body-fonts">After trial and following all processes, your app is ready to launch on the App store or Play Store.</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Box className="card-design-services1">
                                <Grid xs={3} item style={{margin:'0 auto'}}>
                                    <Box>
                                        <img src={Technical} className="new-image-width" alt="requirement-gathering"/>
                                    </Box>
                                </Grid>
                                <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">7. Support & Maintenance</Typography>
                                <Typography className="steps-card-texts body-fonts">Our company offers you all support and the team is always ready to answer every query after deployment.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
export default Step_component;