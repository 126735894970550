import { Box, Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Office from "../../static/image/office.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookSquare, faInstagramSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import Ourteam from "../../static/image/anupraja.jpg";
import Ourteam1 from "../../static/image/ankit.jpg";
import Ourteam2 from "../../static/image/prabin.jpg";
import Ourteam3 from "../../static/image/biraj.jpg";
import Ourteam4 from "../../static/image/sabin.jpg";
import Ourteam5 from "../../static/image/anup.jpg";
import Ourteam6 from "../../static/image/bikit.jpeg";
import Ourteam7 from "../../static/image/anish.jpeg";
import { useNavigate } from "react-router-dom";

const Our_team = ()=>{
    return(
        <Box>
            <Header/>
            <Our_team_page/>
            <Footer/>
        </Box>
    )
}

const Our_team_page = ()=>{
    const history = useNavigate();

    const Career = ()=>{
        history('/career')
    }
    return(
        <Box>
            <Box className="our-team-images">
                <Box className="our-team-background-color">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid md={5} xs={12} item>
                                <Box className="our-team-text-margin">
                                    <Typography className="our-team-text header-fonts">Core Team</Typography>
                                    {/* <Typography className="our-team-small-text body-fonts">We have 6 years of Ruby On Rails development and consulting experiences. We have 6 years of Ruby On Rails development and consulting experiences.</Typography> */}
                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Join Our Team <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box>
                <Container>
                    <Box className="my-client-margin">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text header-fonts">Anup Gautam</Typography>
                                        <Typography className="card-text1 body-fonts">CEO</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam1} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text">Ankit Dawadi</Typography>
                                        <Typography className="card-text1">CTO</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam3} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text">Biraj Khatri</Typography>
                                        <Typography className="card-text1">Director</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam4} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text">Sabin Dawadi</Typography>
                                        <Typography className="card-text1">Director</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam5} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text">Anup Gautam</Typography>
                                        <Typography className="card-text1">Senior Frontend Developer</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="card__collection clear-fix">
                                    <Box className="cards cards--two">
                                        <img src={Ourteam6} class="img-responsive" alt="Cards Image"/>
                                        <span className="cards--two__rect"></span>
                                        <span className="cards--two__tri"></span>
                                        <Typography className="card-text">Bikit Dawadi</Typography>
                                        <Typography className="card-text1">Marketing Officer</Typography>
                                        <ul className="cards__list">
                                            <li><i><FontAwesomeIcon icon={faFacebookSquare}/></i></li>
                                            <li><i><FontAwesomeIcon icon={faInstagramSquare}/></i></li>
                                            <li><i ><FontAwesomeIcon icon={faLinkedin}/></i></li>
                                        </ul>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default Our_team;