import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Blog_image from "../../static/image/blog.jpeg";


const Blog_detail = ()=>{
    return(
        <Box>
            <Header/>
            <Blog_detail_page/>
            <Footer/>
        </Box>
    )
}

const Blog_detail_page = ()=>{
    return(
        <Box>
            <Grid item md={9} xs={12} sm={11} style={{margin:'0 auto'}}>
                <Box className="my-client-margin">
                    <Box>
                        <Typography variant="h1" className="blog-detail-font blog-fonts">App Development Ideas For Finance Companies</Typography>
                    </Box>
                    <Box>
                        <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingTop:'20px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                    </Box>
                    <Box style={{marginTop:'23px'}}>
                        <img src={Blog_image} className="new-image-width" alt="blog-detail" />
                    </Box>
                    <Box style={{marginTop:'15px'}}>
                        <Typography className="card-design-services-another-text1 body-fonts" style={{textAlign:'justify'}}>Lily Jane Collins is a British-American actress. She was better known as the youngest celebrity to many people. She was born on18th March 1989 in Guildford Surrey England.

When she was just 2 years old, she had already participated in the series of the BBC “Growing Pains”. In her childhood, she wrote a column named NY Confidential for the British magazine Elle Girl. If you are curious to know abbot Collins, you are in right place. Let’s move on next</Typography>
                    </Box>
                    <Box style={{marginTop:'15px'}}>
                        <Typography className="card-design-services-another-text1 body-fonts" style={{textAlign:'justify'}}>Lily Jane Collins is a British-American actress. She was better known as the youngest celebrity to many people. She was born on18th March 1989 in Guildford Surrey England.

When she was just 2 years old, she had already participated in the series of the BBC “Growing Pains”. In her childhood, she wrote a column named NY Confidential for the British magazine Elle Girl. If you are curious to know abbot Collins, you are in right place. Let’s move on next</Typography>
                    </Box>
                    <Box style={{marginTop:'15px'}}>
                        <Typography className="card-design-services-another-text1 body-fonts" style={{textAlign:'justify'}}>Lily Jane Collins is a British-American actress. She was better known as the youngest celebrity to many people. She was born on18th March 1989 in Guildford Surrey England.

When she was just 2 years old, she had already participated in the series of the BBC “Growing Pains”. In her childhood, she wrote a column named NY Confidential for the British magazine Elle Girl. If you are curious to know abbot Collins, you are in right place. Let’s move on next</Typography>
                    </Box>
                    <Box style={{marginTop:'15px'}}>
                        <Typography className="card-design-services-another-text1 body-fonts" style={{textAlign:'justify'}}>Lily Jane Collins is a British-American actress. She was better known as the youngest celebrity to many people. She was born on18th March 1989 in Guildford Surrey England.

When she was just 2 years old, she had already participated in the series of the BBC “Growing Pains”. In her childhood, she wrote a column named NY Confidential for the British magazine Elle Girl. If you are curious to know abbot Collins, you are in right place. Let’s move on next</Typography>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default Blog_detail;