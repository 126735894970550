import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Artificial_development from '../templates/pages/artificial';
import Block_development from '../templates/pages/blockchain';
import Blog from '../templates/pages/blog';
import Blog_detail from '../templates/pages/blog-detail';
import Career from '../templates/pages/career';
import Gd_signage from '../templates/pages/gdsignage';
import Home from '../templates/pages/home';
import Hotel from '../templates/pages/hotelmanagement';
import Job from '../templates/pages/jobPortal';
import Logistics from '../templates/pages/logistics';
import Mobile_development from '../templates/pages/mobiledevelopment';
import Our_partner from '../templates/pages/ourPartner';
import OurPortfolio from '../templates/pages/ourPortfolio';
import Our_team from '../templates/pages/ourteam';
import SEO from '../templates/pages/seo';
import Social_development from '../templates/pages/socialmedia';
import Travel from '../templates/pages/travel';
import Web_development from '../templates/pages/webdevelopment';

const Routing = ()=>{
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Home/>}/>
                <Route path='/web/app/development' exact element={<Web_development/>}/>
                <Route path='/mobile/app/development' exact element={<Mobile_development/>}/>
                <Route path='/artificial/intelligence/development' exact element={<Artificial_development/>}/>
                <Route path='/block/chain/development' exact element={<Block_development/>}/>
                <Route path='/seo/development' exact element={<SEO/>}/>
                <Route path='/social/media/marketing' exact element={<Social_development/>}/>
                <Route path='/our/team' exact element={<Our_team/>}/>
                <Route path='/gd/signage' exact element={<Gd_signage/>}/>
                <Route path='/logistics/management/system' exact element={<Logistics/>}/>
                <Route path='/travel/tour/management/system' exact element={<Travel/>}/>
                <Route path='/job/portal/management/system' exact element={<Job/>}/>
                <Route path='/hotel/management/system' exact element={<Hotel/>}/>
                <Route path='/our/portfolio' exact element={<OurPortfolio/>}/>
                <Route path='/career' exact element={<Career/>}/>
                <Route path='/blog' exact element={<Blog/>}/>
                <Route path='/blog/detail' exact element={<Blog_detail/>}/>
                <Route path='/our/partner' exact element={<Our_partner/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Routing;