import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React_native from '../../static/image/logo192.png';
import Flutter from '../../static/image/flutter.svg';
import Android from '../../static/image/android.png';
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Facebook from "../../static/image/facebook.png";
import Instagram from "../../static/image/instagram.png";
import Youtube from "../../static/image/youtube.png";
import Web_page from "../../static/image/web-link.png";
import Photoshop from "../../static/image/photoshop.png";
import Swift from "../../static/image/swift.png";
import Social_media from "../../static/image/social_media.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const Social_development = ()=>{
    return(
        <Box>
            <Header/>
            <Social_development_page/>
            <Footer/>
        </Box>
    )
}

const Social_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid md={5} xs={12} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development14 header-fonts">Social Media<span className="mobile-app-development1"> Marketing</span></Typography>
                            <Typography className="mobile-app-text body-fonts">For past decade, there are multiple business running through the use of social media platforms such as Facebook, Instagram, YouTube, TikTok among others. We provide services for such businesses to effectively grow their audience in these medias.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Social_media} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">The smartphone Apps have changed the definition of every day activity in human life. It has been an essential part of our lives and why not? We do almost everything with the help of an app. The world is connected with mobile apps. In this really techie era, Hyperlink Infosystem can help you to craft some excellent mobile application for your business & start-ups. We are the leading tailor made mobile app development company, serving finest apps across the globe.</Typography>
                        <Typography className="web-design-css body-fonts">We have top mobile app developers who are highly skilled and updated with the latest technology trend. Our team works with the global firms to transform recognized ideas into stunning & inventive mobile Apps. We have rich experience and creativity in producing stunning mobile apps for different clients over the world.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">What We Can Provide</Typography>
                        </Box>
                        <Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Facebook} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Facebook Marketing</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Instagram} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Instagram Marketing</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Youtube} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Youtube Marketing</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Web_page} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Web Page Marketing</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Photoshop} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Content Creation</Typography>
                                        </Box>
                                    </Grid>
                                    {/* <Grid item xs={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Css3} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1" >Css3</Typography>
                                        </Box>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Social_development;