import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Signage from "../../static/image/gd-signage.png";
import Healthcare from "../../static/image/healthcare.png";
import Education from "../../static/image/elearning.png";
import Hospitality from "../../static/image/restaurant.png";
import Bank from "../../static/image/bank.png";
import Mart from "../../static/image/shopping1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const Gd_signage = ()=>{
    return(
        <Box>
            <Header/>
            <Gd_development_page/>
            <Footer/>
        </Box>
    )
}

const Gd_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={3} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid xs={12} md={5} item>
                        <Box className="slider-text-margin" >
                            <Typography className="signage-app-development header-fonts">GD Signage<span className="mobile-app-development1"> System</span></Typography>
                            <Typography className="mobile-app-text body-fonts">A system enabling company to provide the in-premise information of any services through the use of TV monitor.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Signage} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">
We have created GD signage for offices, especially government offices, so that they can give detail information about the location of departments, functionality of departments, notices and other works to the visitors of their offices. This will help these company to manage the information flow, with respect to the customer flow.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Area Where Digital Signage can be Used</Typography>
                        </Box>
                        <Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Healthcare} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Health Sector</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Education} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Education Sector</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Hospitality} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Hospitality Sector</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Bank} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Financial Sector</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Mart} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Marts</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Gd_signage;