import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import SEO1 from "../../static/image/seo_development.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Content from "../../static/image/writer.png";
import Keyword from "../../static/image/keyword.png";
import Picture from "../../static/image/picture.png";
import Page_url from "../../static/image/search.png";
import Meta from "../../static/image/meta.png";
import Social_site from "../../static/image/social-media.png";
import Brand from "../../static/image/brand-image.png";
import Blog from "../../static/image/blog.png";
import Slide from "../../static/image/google.png";
import Title from "../../static/image/title.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const SEO = ()=>{
    return(
        <Box>
            <Header/>
            <SEO_development_page/>
            <Footer/>
        </Box>
    )
}

const SEO_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid xs={12} md={5} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development13 header-fonts">SEO<span className="mobile-app-development1"> Development</span></Typography>
                            <Typography className="mobile-app-text body-fonts">For a business, it is more difficult to reach to their customer, than creating a services or product. Google has been go to search engine for nearly 90% of audience worldwide. So, we have been doing SEO marketing, for enabling business to maintain their presence in the search engine rankings.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={SEO1} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">The smartphone Apps have changed the definition of every day activity in human life. It has been an essential part of our lives and why not? We do almost everything with the help of an app. The world is connected with mobile apps. In this really techie era, Hyperlink Infosystem can help you to craft some excellent mobile application for your business & start-ups. We are the leading tailor made mobile app development company, serving finest apps across the globe.</Typography>
                        <Typography className="web-design-css body-fonts">We have top mobile app developers who are highly skilled and updated with the latest technology trend. Our team works with the global firms to transform recognized ideas into stunning & inventive mobile Apps. We have rich experience and creativity in producing stunning mobile apps for different clients over the world.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                           <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Technology That We Use on Web Development</Typography>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin12 header-fonts">On Page SEO</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Content} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">The content of the page</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Title} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Titles and headings</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Keyword} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts"> Keywords</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Picture} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Images</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Page_url} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">The page’s URL</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Meta} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">The page’s meta description</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin12 header-fonts">Off Page SEO</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Page_url} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Link Building</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Brand} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Brand Building</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Content} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Content Marketing</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Social_site} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Social Media</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Picture} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Image And Video Optimization</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Page_url} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Backlinks</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin12 header-fonts">Content Writing</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Blog} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Blog</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Content} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Artical</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Slide} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Slide</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default SEO;