import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Career_image from "../../static/image/career.jpg";
import AOS from "aos";
import "aos/dist/aos.css";


const Career = ()=>{
    return(
        <Box>
            <Header/>
            <Career_page/>
            <Footer/>
        </Box>
    )
}

const Career_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid md={5} xs={12} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development12 header-fonts">Start Your Career<span className="mobile-app-development1"> with Us</span></Typography>
                            <Typography className="mobile-app-text body-fonts">We are actively hiring interested and brightest individuals to be part of our team. If you don’t want to miss this opportunity to develop some of the best product world wide please fill up the given form.
</Typography>
                            {/* <button className="get-a-quote-button">Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button> */}
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Career_image} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Grid container spacing={3}>
                        <Grid md={4} xs={12} item>
                            <Box style={{textAlign:'start'}}>
                                <Typography className="mobile-app-text body-fonts">General Inquiries</Typography>
                                <Typography className="mobile-app-development121 header-fonts" style={{marginTop:'10px'}}>info@thinkfortech.com</Typography>
                                <Typography className="mobile-app-development121 header-fonts" style={{marginTop:'10px'}}>support@thinkfortech.com</Typography>
                            </Box>
                            <Box style={{textAlign:'start'}}>
                                <Typography className="mobile-app-text body-fonts">Contact us</Typography>
                                <Typography className="mobile-app-development121 header-fonts" style={{marginTop:'10px'}}>Nepal: +977-01-5219420</Typography>
                                <Typography className="mobile-app-development121 header-fonts" style={{marginTop:'10px'}}>Australia: +61-455839226</Typography>
                            </Box> 
                        </Grid>
                        <Grid xs={12} md={8} item>
                            <Box style={{textAlign:'start'}}>
                                <Typography className="mobile-app-text body-fonts">Join our team</Typography>
                            </Box>
                            <Box style={{textAlign:'start',marginTop:'1.5rem'}}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">First Name:*</label>
                                            <input type="text" placeholder="Enter Your First Name"  className="career-form-control body-fonts"/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Last Name:*</label>
                                            <input type="text" placeholder="Enter Your Last Name"  className="career-form-control body-fonts"/>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={{marginTop:'15px'}}>
                                    <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Email:*</label>
                                    <input type="email" placeholder="Enter Your Email"  className="career-form-control body-fonts"/>
                                </Box>
                                <Box style={{marginTop:'15px'}}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Box>
                                                <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Contact Number:*</label>
                                                <input type="number" placeholder="Enter Your Contact Number"  className="career-form-control body-fonts"/>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box>
                                                <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Address:*</label>
                                                <input type="text" placeholder="Enter Your Address"  className="career-form-control body-fonts"/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={{marginTop:'15px'}}>
                                    <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Messege:*</label>
                                    <textarea rows="10" placeholder="Enter Your Messege" className="career-form-control1 body-fonts"></textarea>
                                </Box>
                                <Box style={{marginTop:'15px'}}>
                                    <label className="fs-12 new-text-decoration c-black fw-weight body-fonts">Resume:*</label><br/>
                                    <input type="file" style={{marginTop:'15px'}} className="" />
                                </Box>
                                <Box>
                                    <button className="get-a-quote-button body-fonts" style={{borderRadius:'5px'}}>Submit <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default Career;