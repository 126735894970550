import { Box, Container, Grid, Slider, Typography } from "@material-ui/core";
import React,{useEffect, useState} from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Mobile_app from "../../static/image/mobile_app.png"
import Web_app from "../../static/image/web_app.png"
import AI from "../../static/image/ai_development.png";
import SEO from "../../static/image/seo_development.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Black_horse from "../../static/image/blackhorse.jpg";
import Social_media from "../../static/image/social_media.png";
import Mobile_icon from "../../static/image/mobile-phone.png";
import Block_chain from "../../static/image/block-chain.png";
import Android from '../../static/image/android.png';
import IOS from '../../static/image/apple.png';
import React_native from '../../static/image/logo192.png';
import Flutter from '../../static/image/flutter.svg';
import Web_site from "../../static/image/website.png";
import Python from "../../static/image/python.png";
import Node_js from "../../static/image/nodejs.png";
import Postgre from "../../static/image/postgre.png";
import Mongo from "../../static/image/mongodb.svg";
import Artifical from '../../static/image/artificial-intelligence.png';
import Text_speech from "../../static/image/text-to-speech.png";
import Business from "../../static/image/business.png";
import Forecast from "../../static/image/forecast.png";
import Data from '../../static/image/monitor.png';
import Seo_image from '../../static/image/seo.png';
import On_page from "../../static/image/find-on-page.png";
import Resume from "../../static/image/resume.png";
import Application from "../../static/image/application.png";
import Smart_contract from "../../static/image/contract.png";
import Team from "../../static/image/team.png";
import Payment from "../../static/image/payment-gateway.png";
import Facebook from "../../static/image/facebook.png";
import Instagram from "../../static/image/instagram.png";
import Youtube from "../../static/image/youtube.png";
import Web_page from "../../static/image/web-link.png";
import Photoshop from "../../static/image/photoshop.png";
import Writing from "../../static/image/writer.png";
import Deployment from "../../static/image/deployment.png";
import Experience from "../../static/image/experience.png";
import Company_rating from "../../static/image/quality-control.png";
import App_download from "../../static/image/smartphone.png";
import Developer from "../../static/image/coding.png";
import Huawei from '../../static/image/huawei.png';
import Misfit from "../../static/image/misfit.jpg";
import Alice from "../../static/image/alice.jpg";
import Ecommerce from "../../static/image/shopping.png";
import Restaurant from "../../static/image/restaurant.png";
import Realestate from "../../static/image/house.png";
import Travel from "../../static/image/travel.png";
import Elearning from "../../static/image/elearning.png";
import Logistics from "../../static/image/logistics.png";
import Social_site from "../../static/image/social-media.png";
import Health_care from "../../static/image/healthcare.png";
import Blog_image from "../../static/image/blog.jpeg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Step_component from "../component/stepComponent";
import Caliber from "../../static/image/caliber.png";
import Royal from "../../static/image/royal.jpg";
import Chito from "../../static/image/chito.jpeg";
import ViewResort from "../../static/image/360.jpeg";
import Safal from "../../static/image/safal.jpeg";
import Peace from "../../static/image/peace.png";
import Dream from "../../static/image/dream.webp";
import NGO from "../../static/image/ngologo.png";
import Janak from "../../static/image/janak.jpg";
import Durbar from "../../static/image/durbar.png";
import OldHouse from "../../static/image/oldhouse.jpeg";
import WriterInformation from "../../static/image/writerinformation.png";
import iFarmer from "../../static/image/ifarmer.svg";



const Home = ()=>{
    return(
        <Box>
            <Header/>
            <Home_page/>
            <Footer/>
        </Box>
    )
}

const Home_page = ()=>{
    const history = useNavigate();
      const [SliderData,setSliderData] = useState(true);
      const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 7
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };
      useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

      const WebDesign = (e)=>{
        history('/web/app/development')
      }
      const MobileApp = (e)=>{
        history('/mobile/app/development')
      }
      const ArtificalNew = (e)=>{
        history('/artificial/intelligence/development')
      }
      const BlockChain = (e)=>{
        history('/block/chain/development')
      }
      const SEODevelopment = (e)=>{
        history('/seo/development')
      }
      const SocialMedia = (e)=>{
        history('/social/media/marketing')
      }

      const Career = ()=>{
          history('/career')
      }
      
    return(
        <Box>
            <Box>
                    <Box className="my-left-margin res-display">
                        <Grid container spacing={2}>
                            <Grid xs={1} item>
                                <Box className="vertical-line-design">
                                    <Typography className={SliderData==true?"active-button-slider active-top-left active-left":"active-button-slider1 active-top-left active-left1"} onClick={()=>setSliderData(true)}>1</Typography>
                                    <Typography className={SliderData=='2'?"active-button-slider active-top-left1 active-left":"active-button-slider1 active-top-left1 active-left1"} onClick={()=>setSliderData('2')}>2</Typography>
                                    <Typography className={SliderData=='3'?"active-button-slider active-top-left2 active-left":"active-button-slider1 active-top-left2 active-left1"} onClick={()=>setSliderData('3')}>3</Typography>
                                    <Typography className={SliderData=='4'?"active-button-slider active-top-left3 active-left":"active-button-slider1 active-top-left3 active-left1"} onClick={()=>setSliderData('4')}>4</Typography>
                                    <Typography className={SliderData=='5'?"active-button-slider active-top-left4 active-left":"active-button-slider1 active-top-left4 active-left1"} onClick={()=>setSliderData('5')}>5</Typography>
                                    <Typography className={SliderData=='6'?"active-button-slider active-top-left5 active-left":"active-button-slider1 active-top-left5 active-left1"} onClick={()=>setSliderData('6')}>6</Typography>
                                </Box>
                            </Grid>
                            <Grid xs={11} item>
                                {
                                    SliderData==true?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development11 header-fonts">Web App<span className="mobile-app-development1"> Development</span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">Every company at present requires a web-based application for automation of their business processes. Be it a small static website or full-fledged web application, we have been serving all types of client, according to their needs.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={Web_app} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                                {
                                    SliderData=='2'?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development header-fonts">Mobile App<span className="mobile-app-development1"> Development</span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">With increased use of handheld portable devices, businesses have been exploring this unique opportunity to reach the wider customer. We develop cutting edge mobile application, suitable for both android and iOS to fulfill this need.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={Mobile_app} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                                {
                                    SliderData=='3'?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development12 header-fonts">Artificial Intelligence<span className="mobile-app-development1"> Development</span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">A buzz term some few years ago, however at present it has been successful in solving many important real-world applications, many of which we are experiencing at our day to day use. We provide our client with services in fields of machine learning, computer vision, NLP, real time data forecasting, among others.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={AI} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                                {
                                    SliderData=='4'?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development13 header-fonts">SEO<span className="mobile-app-development1"></span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">For a business, it is more difficult to reach to their customer, than creating a services or product. Google has been go to search engine for nearly 90% of audience worldwide. So, we have been doing SEO marketing, for enabling business to maintain their presence in the search engine rankings.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={SEO} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                                 {
                                    SliderData=='5'?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development14 header-fonts">Social Media<span className="mobile-app-development1"> Marketing</span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">For past decade, there are multiple business running through the use of social media platforms such as Facebook, Instagram, YouTube, TikTok among others. We provide services for such businesses to effectively grow their audience in these medias.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={Social_media} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                                {
                                    SliderData=='6'?
                                        <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                                            <Grid xs={5} item>
                                                <Box className="slider-text-margin">
                                                    <Typography className="mobile-app-development header-fonts">Blockchain<span className="mobile-app-development1"> Development</span></Typography>
                                                    <Typography className="mobile-app-text body-fonts">With the growing cyber security issues and lack of trust among the business stakeholders, digitalizing some of the business domains have frighteningly plateaued. We provide enterprise blockchain solution to such business need, so that they can automate their business activities, without any wariness.</Typography>
                                                    <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <img src={Block_chain} alt="mobile-app-development" className="new-image-width" />
                                            </Grid>
                                        </Grid>:null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                     <Container> 
                        <Box>
                            <Box className="new-body-text">
                                <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Our <span>Clients</span></Typography>
                            </Box>
                            <Box className="my-client-margin">
                                <Carousel responsive={responsive} autoPlay>
                                    <Box className="slider-new-margin-left">
                                        <img src={Black_horse} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Caliber} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Royal} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Chito} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={ViewResort} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Safal} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Peace} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Dream} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={NGO} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Janak} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={Durbar} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                    <Box className="slider-new-margin-left">
                                        <img src={OldHouse} style={{height:'150px'}} className="new-image-width" alt="clients"/>
                                    </Box>
                                </Carousel>
                            </Box>
                        </Box>
                        <Box>
                            <Box className="new-body-text">
                                <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Services <span>We Offer</span></Typography>
                            </Box>
                            <Box className="my-client-margin">
                                <Grid container spacing={3}>
                                    <Grid md={4} xs={12} sm={6} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Web_site} className="new-image-width" alt="web-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">Web App Development</Typography>
                                                </Grid>
                                            </Grid>
                                           <Box className="services-height-for-read-more">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Python} className="card-images-design" alt="android-app" /> Python</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Node_js} className="card-images-design" alt="android-app" /> Node JS</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={React_native} className="card-images-design" alt="android-app" /> React JS</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Postgre} className="card-images-design" alt="android-app" /> PostgreSQL</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Mongo} className="card-images-design" alt="android-app" /> Mongo DB</Typography>
                                                </Box>
                                           </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={WebDesign}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid md={4} xs={12} sm={6} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Mobile_icon} className="new-image-width" alt="mobile-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">Mobile App Development</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box className="services-height-for-read-more">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Android} className="card-images-design" alt="android-app" /> Android App</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={IOS} className="card-images-design" alt="android-app" /> IOS App</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={React_native} className="card-images-design" alt="android-app" /> React Native</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Flutter} className="card-images-design" alt="android-app" /> Flutter</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={MobileApp}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid md={4} sm={6} xs={12} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Artifical} className="new-image-width" alt="mobile-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">Artificial Intelligence(AI) Development</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box className="services-height-for-read-more1">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Text_speech} className="card-images-design" alt="android-app" /> Text to Speech</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Business} className="card-images-design" alt="android-app" /> Business Intelligence</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Forecast} className="card-images-design" alt="android-app" /> Data Forecasting</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Data} className="card-images-design" alt="android-app" /> Data Analytics</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={ArtificalNew}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid md={4} sm={6} xs={12} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Artifical} className="new-image-width" alt="mobile-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">Block Chain Development</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box className="services-height-for-read-more">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Application} className="card-images-design" alt="android-app" /> Decentralized Application</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Smart_contract} className="card-images-design" alt="android-app" /> Smart Contract</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Team} className="card-images-design" alt="android-app" /> Enterprise Solution</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Payment} className="card-images-design" alt="android-app" /> Micro Payment Solution</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={BlockChain}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid md={4} sm={6} xs={12} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Seo_image} className="new-image-width" alt="web-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">SEO Development</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box className="services-height-for-read-more">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={On_page} className="card-images-design" alt="android-app" /> On Page SEO</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Resume} className="card-images-design" alt="android-app" /> Off Page SEO</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Writing} className="card-images-design" alt="android-app" /> Content Writing</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={SEODevelopment}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid md={4} sm={6} xs={12} item >
                                        <Box className="card-design-services">
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <img src={Mobile_icon} className="new-image-width" alt="mobile-app-development"/>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography className="card-design-services-text header-fonts">Social Media Marketing</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box className="services-height-for-read-more">
                                                <Box style={{marginTop:'1.7rem'}}>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Facebook} className="card-images-design" alt="android-app" /> Facebook Boosting</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Instagram} className="card-images-design" alt="android-app" /> Instagram Boosting</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Youtube} className="card-images-design" alt="android-app" /> Youtube Boosting</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Web_page} className="card-images-design" alt="android-app" /> Web Site Boosting</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography className="card-design-services-another-text body-fonts"><img src={Photoshop} className="card-images-design" alt="android-app" /> Content Creation</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{textAlign:'start',marginTop:'20px'}}>
                                                <button className="learn-more body-fonts" onClick={SocialMedia}>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow"></span>
                                                    </span>
                                                    <span className="button-text">Read More...</span>
                                                </button>
                                            </Box>
                                            <Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <Step_component/>
                            </Box>
                            <Box className="my-client-margin" id="about_us">
                                <Grid container spacing={3}>
                                    <Grid item md={5} sm={12} xs={12}>
                                        <Box>
                                            <Typography className="fs-2 fw-bolder header-fonts" variant="h1" style={{textAlign:'start'}}>About Us</Typography>
                                        </Box>
                                        <Box style={{marginTop:'1.3rem'}}>
                                            <Typography className="card-design-services-another-text2 body-fonts">From Software Development to Digital Marketing, we
specialize in offering a range of tech services that are in
demand here, in Nepal and Australia to help its economy digitalize.

Our company competes in a highly fragmented and
competitive industry.</Typography>
                                        </Box>
                                        <Box className="all-margin">
                                            <Typography className="card-design-services-another-text2 body-fonts">It has grown quite substantially from early beginnings, and
today we have partnered with international companies such as
Alice Labs, Huawei, Misfit, Writer Information, i-Farmer etc. to attain the digitalization in our
country.</Typography>
                                        </Box>
                                        <Box className="all-margin">
                                            <Typography className="card-design-services-another-text2 body-fonts">Our tech products and services are based on accumulated
expertise of our dedicated and visionary team of professionals
who specialize in this field for many years.</Typography>
                                        </Box>
                                        <Box className="all-margin">
                                            <Typography className="card-design-services-another-text2 body-fonts">We believe that, with our willingness to try new things and
staying at the edge of innovative services we will provide
customers with best solutions.</Typography>
                                        </Box>
                                        <Box className="all-margin" style={{textAlign:'start'}}>
                                            <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">GLOBAL PARTNERS</Typography>
                                        </Box>
                                        <Box className="all-margin">
                                            <Grid container spacing={3}>
                                                <Grid item xs={4}>
                                                    <img src={Huawei} className="new-image-width" alt="huawei" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <img src={Misfit} className="new-image-width" alt="huawei" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <img src={Alice} className="new-image-width" alt="huawei" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <img src={WriterInformation} className="new-image-width" alt="huawei" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <img src={iFarmer} className="new-image-width" alt="huawei" />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item md={7} xs={12} sm={12}>
                                        <Grid container spacing={3}>
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2">
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={Experience} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">5<span style={{marginLeft:'3px'}}>+</span></Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Years Experience in Software Design and Development.</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2" style={{marginTop:'1.5rem'}}>
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={Mobile_icon} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">13<span style={{marginLeft:'3px'}}>+</span></Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Mobile Apps Developed.</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2">
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={Deployment} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">99<span style={{marginLeft:'3px'}}>+</span></Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Websites Developed.</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2" >
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={App_download} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">9<span style={{marginLeft:'3px'}}>+</span></Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Management System Development.</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2" style={{marginTop:'1.5rem'}}>
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={Company_rating} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">4.5</Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Clients Review</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                           
                                            <Grid md={4} xs={12} sm={6} item>
                                                <Box className="card-design-services2">
                                                    <Grid xs={4} item style={{margin:'0 auto'}}>
                                                        <Box>
                                                            <img src={Developer} className="new-image-width" alt="requirement-gathering"/>
                                                        </Box>
                                                    </Grid>
                                                    <Box className="all-margin">
                                                        <Typography className="mobile-app-development999 header-fonts">20<span style={{marginLeft:'3px'}}>+</span></Typography>
                                                    </Box>
                                                    <Box style={{marginTop:'7px'}}>
                                                        <Typography className="card-design-services-another-text1 body-fonts">Dedicated Developers & Designers.</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Box className="new-body-text">
                                    <Typography variant="h1" className="fs-2 fw-bolder header-fonts">What <span>We Serve</span></Typography>
                                </Box>
                                <Box className="my-client-margin">
                                    <Grid container spacing={3}>
                                        <Grid md={3} xs={6} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Ecommerce} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Retail & Ecommerce</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid md={3} xs={6} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Travel} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Travel & Hospitality</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid md={3} xs={6} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Realestate} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Real Estate</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid md={3} xs={6} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Restaurant} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Food & Restaurant</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} md={3} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Elearning} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Education & E-learning</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} md={3} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Logistics} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Logistics & Distribution</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} md={3} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Social_site} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Social Media Marketing</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid xs={6} md={3} sm={4} item>
                                            <Box className="card-design-services3">
                                                <Grid xs={4} item style={{margin:'0 auto'}}>
                                                    <Box>
                                                        <img src={Health_care} className="new-image-width" alt="requirement-gathering"/>
                                                    </Box>
                                                </Grid>
                                                <Box>
                                                    <Typography variant="h3" className="fs-1 steps-text-margin header-fonts">Healthcare & Fitness</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            {/* <Box>
                                <Box className="new-body-text">
                                    <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Our Blogs </Typography>
                                </Box>
                                <Box className="my-client-margin">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={4} sm={6}>
                                            <Box>
                                                <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                            </Box>
                                            <Box>
                                                <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                                <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={6}>
                                            <Box>
                                                <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                            </Box>
                                            <Box>
                                                <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                                <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} sm={6}>
                                            <Box>
                                                <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                            </Box>
                                            <Box>
                                                <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                                <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box> */}
                        </Box>
                    </Container>
                </Box>
            </Box>
    )
}

export default Home;