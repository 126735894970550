import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Huawei from '../../static/image/huawei.png';
import Misfit from "../../static/image/misfit.jpg";
import Alice from "../../static/image/alice.jpg";
import NPS from "../../static/image/nfs.jpg";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Chat from "../../static/image/chat.png";
import Face from "../../static/image/facial-recognition.png";
import Ecommerce from "../../static/image/shopping1.png";
import Process from "../../static/image/work-process.png";
import Logistics from "../../static/image/logistics.png";
import Social_site from "../../static/image/social-media.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Partner from "../../static/image/partner.png";
import Step_component from "../component/stepComponent";
import WriterInformation from "../../static/image/writerinformation.png";
import iFarmer from "../../static/image/ifarmer.svg";
import { useNavigate } from "react-router-dom";


const Our_partner = ()=>{
    return(
        <Box>
            <Header/>
            <Our_partner_page/>
            <Footer/>
        </Box>
    )
}

const Our_partner_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid xs={12} md={5} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development12">Our Partner's</Typography>
                            <Typography className="mobile-app-text">We have affiliated with different partners worldwide to develop our global presence. Some of our national as well as international partners includes.</Typography>
                            <button className="get-a-quote-button" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Partner} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box>
                    <Box className="new-body-text">
                        <Typography variant="h1" className="fs-2 fw-bolder">Global Partners </Typography>
                    </Box>
                    <Box className="my-client-margin">
                        <Grid container spacing={3}>
                            <Grid item xs={3} md={2}>
                                <img src={Misfit} className="new-image-width" alt="huawei" />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <img src={Alice} className="new-image-width" alt="huawei" />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <img src={Huawei} className="new-image-width" alt="huawei" />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <img src={WriterInformation} className="new-image-width" alt="huawei" />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <img src={iFarmer} className="new-image-width" alt="huawei" />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder">Our Partner Products</Typography>
                        </Box>
                        <Box className="my-client-margin">
                            <Grid container spacing={3}>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Chat} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">Alice Chat Bot</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Face} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">eKYC</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Ecommerce} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">Loyalty Management System</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Process} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">InConnect</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Logistics} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">SuperSonic</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                    <Box className="web-design-services-card1">
                                        <Grid item xs={4} style={{margin:'0 auto'}}>
                                            <img src={Social_site} className="new-image-width" alt="react-image"/>
                                        </Grid>
                                        <Typography className="web-design-css1">Smartbees</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>   
                </Box>
            </Container>
        </Box>
    )
}

export default Our_partner