import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Logo from '../../static/image/gd_logo.png';
import { faFacebookSquare,faInstagramSquare, faLinkedin, faLinkedinIn, faTwitterSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


const Footer = ()=>{
    return(
        <Box className="footer-background">
            <Container>
                <Grid container className="new-footer-padding">
                    <Grid item md={4} xs={12}>
                        <Grid item md={8} className="res-text-align">
                            <img src={Logo} className="new-width" alt="logo"/>
                        </Grid>
                    </Grid>
                    <Grid md={2} xs={12} item>
                        <Typography className="footer-text header-fonts">Our Services</Typography>
                        <Box className="my-border-bottom2">
                        </Box>
                        <Box>
                            <Link to="/web/app/development" style={{textDecoration:'none'}} className="footer-link body-fonts">Web Development</Link>
                            <Link to="/mobile/app/development" style={{textDecoration:'none'}} className="footer-link body-fonts">App Development</Link>
                            <Link to="/block/chain/development" style={{textDecoration:'none'}} className="footer-link body-fonts">Block Chain</Link>
                        </Box>
                    </Grid>
                    <Grid md={2} xs={12} item>
                        <Typography className="footer-text header-fonts">Digital Marketing</Typography>
                        <Box className="my-border-bottom2">
                        </Box>
                        <Box>
                            <Link to='/social/media/marketing' style={{textDecoration:'none'}} className="footer-link body-fonts">Social Media Marketing</Link>
                            <Link to='/seo/development' style={{textDecoration:'none'}} className="footer-link body-fonts">SEO Development</Link>
                        </Box>
                    </Grid>
                    <Grid md={2} xs={12} item>
                        <Typography className="footer-text header-fonts">Our Products</Typography>
                        <Box className="my-border-bottom2">
                        </Box>
                        <Box>
                            <Link to="/gd/signage" style={{textDecoration:'none'}} className="footer-link body-fonts">G D Signage</Link>
                            <Link to="/job/portal/management/system" style={{textDecoration:'none'}} className="footer-link body-fonts">Job Portal Sytem</Link>
                            <Link to="/hotel/management/system" style={{textDecoration:'none'}} className="footer-link body-fonts">Hotel Management System</Link>
                        </Box>
                    </Grid>
                    <Grid md={2} xs={12} item>
                        <Typography className="footer-text header-fonts">About</Typography>
                        <Box className="my-border-bottom2">
                        </Box>
                        <Box>
                            <a href="/#about_us" className="footer-link body-fonts" style={{textDecoration:'none'}} >About Company</a>
                            <Link to="/career" className="footer-link body-fonts" style={{textDecoration:'none'}} >Career</Link>
                            <Link to="/blog" className="footer-link body-fonts" style={{textDecoration:'none'}} >Blog</Link>
                            {/* <Link to="#" className="footer-link" style={{textDecoration:'none'}} >Support</Link> */}
                        </Box>
                    </Grid>
                </Grid>
                <Box className="my-down-footer">
                    <Grid container>
                        <Grid item md={11} xs={12}>
                            <Typography className="copy-right-fonts body-fonts">Copyright © 2022 All rights reserved to by Think 4 Tech Pvt. Ltd.</Typography>
                        </Grid>
                        <Grid item md={1} xs={12}>
                            <Box className="my-flex-design res-margin-top res-text-align">
                                <a href="https://www.facebook.com/developersgd" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} className="copy-right-fonts1"/></a>
                                <a href="https://www.instagram.com/think_4_tech/" target="_blank" className="my-socail-margin"><FontAwesomeIcon icon={faInstagramSquare} className="copy-right-fonts1" style={{marginLeft:'5px'}}/></a>
                                <a href="https://www.youtube.com/channel/UC0kyofsWNQMLuIdi9MFLsqQ" target="_blank" className="my-socail-margin"><FontAwesomeIcon icon={faYoutubeSquare} className="copy-right-fonts1" style={{marginLeft:'5px'}}/></a>
                                <a href="https://www.linkedin.com/company/g-and-d-developers-nepal/" target="_blank" className="my-socail-margin"><FontAwesomeIcon icon={faLinkedin} className="copy-right-fonts1" style={{marginLeft:'5px'}}/></a>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
export default Footer;