import logo from './logo.svg';
import './App.css';
import Routing from './routing/routing';
import './static/css/style.css';
import './static/css/responsive.css';

function App() {
  return (
    <div className="App">
      <Routing/>
    </div>
  );
}

export default App;
