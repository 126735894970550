import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Nepal_logo from '../../static/image/nepal.png';
import Australia_logo from '../../static/image/australia.png';
import Company_logo from "../../static/image/gd_logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown, faMinusSquare, faPlus, faPlusSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare,faInstagramSquare, faLinkedin, faLinkedinIn, faTwitterSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import Facebook from "../../static/image/facebook.png";
import Instagram from "../../static/image/instagram.png";
import Youtube from "../../static/image/youtube.png";
import Linkedin from "../../static/image/linkedin.png";


const Header = ()=>{
    return(
        <Box>
            <Sub_header/>
            <Main_header/>
        </Box>
    )
}

const Sub_header = ()=>{
    return(
        <Box className="sub-header-design res-display">
            <Container>
                <Grid container>
                    <Grid item xs={7}>
                        <Grid container spacing={0}>
                            <Grid xs={3}>
                                <Grid container spacing={1}>
                                    <Grid xs={2} item>
                                        <img src={Nepal_logo} className="logo-image" alt='nepal-logo'/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="c-black fs-15 c-black fw-weight body-fonts">+977-01-5219420</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={3}>
                                <Grid container spacing={1}>
                                    <Grid xs={3} item>
                                        <img src={Australia_logo} className="logo-image new-border-radius" alt='nepal-logo'/>
                                    </Grid>
                                    <Grid item>
                                        <Typography className="c-black fs-15 c-black fw-weight body-fonts">+61-455839226</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={4} item>

                    </Grid>
                    <Grid xs={1} item>
                        <Grid container spacing={1} className="my-flex-design">
                            <Grid item xs={3}>
                                <a href="https://www.facebook.com/developersgd" target="_blank"><img src={Facebook} className="new-image-width" alt="facebook" /></a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href="https://www.instagram.com/think_4_tech/" target="_blank"><img src={Instagram} className="new-image-width" alt="instagram" /></a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href="https://www.youtube.com/channel/UC0kyofsWNQMLuIdi9MFLsqQ" target="_blank"><img src={Youtube} className="new-image-width" alt="youtube" /></a>
                            </Grid>
                            <Grid item xs={3}>
                                <a href="https://www.linkedin.com/company/g-and-d-developers-nepal/" target="_blank"><img src={Linkedin} className="new-image-width" alt="linkedin" /></a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const Main_header = ()=>{
    const [HeaderShow,setHeaderShow] = useState(false);
    const [ServicesShow,setServiceShow] = useState(false);
    const [ProductShow,setProductShow] = useState(false);
    return(
        <>
        <Box className="main-header-design res-display">
            <Container>
                <Grid container spacing={0}>
                    <Grid xs={1} item className="new-text-left">
                        <Link to="/"><img src={Company_logo} className="company-logo" alt="company-logo"/></Link>
                    </Grid>
                    <Grid xs={11} item className="new-text-left">
                        <List className="new-padding-top">
                            <ListItem className="nav-bar-display"><Link to="/" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Home</Link></ListItem>
                            <ListItem className="nav-bar-display new-drop-down-bar"><a href="/#about_us" className="fs-15 new-text-decoration c-white fw-weight body-fonts">About Us</a>
                                {/* <Box className="drop-down-box">
                                    <Link to="/" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag">What We Do</Link>
                                    <Link to="/" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag">About Us</Link>
                                    <Link to="/" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag">Mission & Vision</Link>
                                </Box> */}
                            </ListItem>
                            <ListItem className="nav-bar-display new-drop-down-bar"><Link to="#" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Our Services <FontAwesomeIcon icon={faChevronDown} className="fs-12" style={{marginLeft:'3px'}} /></Link>
                                <Box className="drop-down-box">
                                    <Link to="/web/app/development" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Web App Development</Link>
                                    <Link to="/mobile/app/development" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Mobile App Development</Link>
                                    <Link to="/artificial/intelligence/development" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Artificial Intelligence</Link>
                                    <Link to="/seo/development" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">SEO</Link>
                                    <Link to="/social/media/marketing" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Social Media Marketing</Link>
                                    <Link to="/block/chain/development" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Block Chain Development</Link>
                                </Box>
                            </ListItem>
                            <ListItem className="nav-bar-display new-drop-down-bar"><Link to="/" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Our Products <FontAwesomeIcon icon={faChevronDown} className="fs-12" style={{marginLeft:'3px'}} /></Link>
                                <Box className="drop-down-box">
                                    <Link to="/gd/signage" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">G D Signage</Link>
                                    <Link to="/logistics/management/system" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Logistic Management System</Link>
                                    <Link to="/travel/tour/management/system" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Travel & Tour Management System</Link>
                                    <Link to="/job/portal/management/system" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Job Portal Management System</Link>
                                    {/* <Link to="/" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag">Dental Management System</Link> */}
                                    <Link to="/hotel/management/system" className="fs-15 new-text-decoration c-black fw-weight drop-down-link-tag body-fonts">Hotel Management System</Link>
                                </Box>
                            </ListItem>
                            <ListItem className="nav-bar-display"><Link to="/our/portfolio" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Our Portfolio</Link></ListItem>
                            <ListItem className="nav-bar-display"><Link to="/our/team" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Our Team</Link></ListItem>
                            <ListItem className="nav-bar-display"><Link to="/our/partner" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Our Partner</Link></ListItem>
                            <ListItem className="nav-bar-display"><Link to="/career" className="fs-15 new-text-decoration c-white fw-weight body-fonts">Career</Link></ListItem>
                            {/* <ListItem className="nav-bar-display"><Link to="/" className="fs-15 new-text-decoration c-black fw-weight">Contact us</Link></ListItem> */}
                            {/* <ListItem className="nav-bar-display"><Link to="/blog" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Blog</Link></ListItem> */}
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        <Box className="res-display-block" style={{display:'none'}}>
            <Box className=" res-header">
                <Grid container >
                    <Grid item xs={6}>
                        <Grid container >
                            <Grid item xs={1}>
                                <Link to="/"><img src={Company_logo} className="company-logo" alt="company-logo"/></Link>
                            </Grid>
                            <Grid item xs={11}>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        {
                            !HeaderShow==true?
                            <FontAwesomeIcon onClick={()=>setHeaderShow(true)} className="fam-24" icon={faBars}/>:
                            <FontAwesomeIcon onClick={()=>setHeaderShow(false)} className="fam-24"  icon={faTimes}/>
                        }
                    </Grid>
                </Grid>
            </Box>
            {
                HeaderShow==true?
                    <Box style={{padding:'16px'}}>
                        <Box className="res-header-background-bottom" >
                            <ListItem className="res-body-border"><a href="/#about_us" className="fs-15 new-text-decoration c-black fw-weight body-fonts">About Us</a></ListItem>
                            <ListItem className="res-body-border"  onClick={!ServicesShow==true?()=>setServiceShow(true):()=>setServiceShow(false)}><Grid container><Grid xs={11} item><Link to="#" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Our Services</Link></Grid><Grid item xs={1} style={{textAlign:'right'}}>{!ServicesShow==true?<FontAwesomeIcon icon={faPlusSquare}/>:<FontAwesomeIcon icon={faMinusSquare}/>}</Grid></Grid></ListItem>
                            {
                                ServicesShow==true?
                                <Box>
                                    <ListItem className="res-body-border"><Link to="/web/app/development" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Web App Development</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/mobile/app/development" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Mobile App Development</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/artificial/intelligence/development" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Artificial Intelligence</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/seo/development" className="fs-15 new-text-decoration c-black fw-weight body-fonts">SEO</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/social/media/marketing" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Social Media Marketing</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/block/chain/development" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Block Chain Development</Link></ListItem>
                                </Box>:null
                            }
                            <ListItem className="res-body-border"  onClick={!ProductShow==true?()=>setProductShow(true):()=>setProductShow(false)}><Grid container><Grid xs={11} item><Link to="#" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Our Products</Link></Grid><Grid item xs={1} style={{textAlign:'right'}}>{!ProductShow==true?<FontAwesomeIcon icon={faPlusSquare}/>:<FontAwesomeIcon icon={faMinusSquare}/>}</Grid></Grid></ListItem>
                            {
                                ProductShow==true?
                                <Box>
                                    <ListItem className="res-body-border"><Link to="/gd/signage" className="fs-15 new-text-decoration c-black fw-weight  body-fonts">G D Signage</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/logistics/management/system" className="fs-15 new-text-decoration c-black fw-weight  body-fonts">Logistic Management System</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/travel/tour/management/system" className="fs-15 new-text-decoration c-black fw-weight  body-fonts">Travel & Tour Management System</Link></ListItem>
                                    <ListItem className="res-body-border"><Link to="/job/portal/management/system" className="fs-15 new-text-decoration c-black fw-weight  body-fonts">Job Portal Management System</Link></ListItem>
                                    {/* <ListItem className="res-body-border"><Link to="/" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Dental Management System</Link></ListItem> */}
                                    <ListItem className="res-body-border"><Link to="/hotel/management/system" className="fs-15 new-text-decoration c-black fw-weight  body-fonts">Hotel Management System</Link></ListItem>
                                </Box>:null
                            }
                            <ListItem className="res-body-border"><Link to="/our/portfolio" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Our Portfolio</Link></ListItem>
                            <ListItem className="res-body-border"><Link to="/our/team" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Our Team</Link></ListItem>
                            <ListItem className="res-body-border"><Link to="/our/partner" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Our Partner</Link></ListItem>
                            <ListItem className="res-body-border"><Link to="/career" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Career</Link></ListItem>
                            {/* <ListItem className="res-body-border"><Link to="/blog" className="fs-15 new-text-decoration c-black fw-weight body-fonts">Blog</Link></ListItem> */}
                        </Box>
                    </Box>:null
            }
        </Box>
        </>
    )
}
export default Header;