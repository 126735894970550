import { faArrowRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Blog_background from "../../static/image/blog-background.png";
import Blog_image from "../../static/image/blog.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";


const Blog = ()=>{
    return(
        <Box>
            <Header/>
            <Blog_page/>
            <Footer/>
        </Box>
    )
}

const Blog_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid md={5} xs={12} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development12 header-fonts">Our Blog</Typography>
                            <Typography className="mobile-app-text body-fonts">We have 6 years of Ruby On Rails development and consulting experiences. We have 6 years of Ruby On Rails development and consulting experiences.</Typography>
                            <button className="get-a-quote-button body-fonts">Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Blog_background} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box>
                    <Box className="new-body-text">
                        <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Listed Blogs </Typography>
                    </Box>
                    <Box className="my-client-margin12">
                        <Grid container spacing={3}>
                            <Grid item md={4} sm={6} xs={12}>
                                <Box>
                                    <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                </Box>
                                <Box>
                                    <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                    <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Box>
                                    <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                </Box>
                                <Box>
                                    <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                    <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <Box>
                                    <img src={Blog_image} className="new-image-width" alt="blog-image" />
                                </Box>
                                <Box>
                                    <Typography variant="h1" className="mobile-app-development1212 blog-fonts">This is the Title</Typography>
                                    <Typography className="fs-14 body-fonts" style={{textAlign:'start',paddingLeft:'10px'}}><FontAwesomeIcon icon={faCalendarAlt} style={{marginRight:'3px'}}/>{Date.now()}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Blog;