import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import AI from "../../static/image/ai_development.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Text_speech from "../../static/image/text-to-speech.png";
import Recommendation from "../../static/image/recomendation.png";
import Process from "../../static/image/work-process.png";
import Chat from "../../static/image/chat.png";
import Inventory from "../../static/image/inventory.png";
import Face from "../../static/image/facial-recognition.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const Artificial_development = ()=>{
    return(
        <Box>
            <Header/>
            <Artificial_development_page/>
            <Footer/>
        </Box>
    )
}

const Artificial_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={3} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid xs={12} md={5} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development12 header-fonts">Artificial Intelligence<span className="mobile-app-development1"> Development</span></Typography>
                            <Typography className="mobile-app-text body-fonts">A buzz term some few years ago, however at present it has been successful in solving many important real-world applications, many of which we are experiencing at our day to day use. We provide our client with services in fields of machine learning, computer vision, NLP, real time data forecasting, among others.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item xs={7} className="res-display">
                        <img src={AI} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">The smartphone Apps have changed the definition of every day activity in human life. It has been an essential part of our lives and why not? We do almost everything with the help of an app. The world is connected with mobile apps. In this really techie era, Hyperlink Infosystem can help you to craft some excellent mobile application for your business & start-ups. We are the leading tailor made mobile app development company, serving finest apps across the globe.</Typography>
                        <Typography className="web-design-css body-fonts">We have top mobile app developers who are highly skilled and updated with the latest technology trend. Our team works with the global firms to transform recognized ideas into stunning & inventive mobile Apps. We have rich experience and creativity in producing stunning mobile apps for different clients over the world.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">What We Can Provide</Typography>
                        </Box>
                        <Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Text_speech} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Voice-based AI</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Recommendation} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Recommendation Engine</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Process} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Process Automation</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Chat} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Chat Bots</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Inventory} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Inventory Optimization</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Face} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Face Recognition</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Artificial_development;