import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Hotel_management from "../../static/image/hotel-management.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";

const Hotel = ()=>{
    return(
        <Box>
            <Header/>
            <Hotel_development_page/>
            <Footer/>
        </Box>
    )
}

const Hotel_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={3} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid md={5} xs={12} item>
                        <Box className="slider-text-margin">
                            <Typography className="signage-app-development header-fonts">Hotel Management<span className="mobile-app-development1"> System</span></Typography>
                            <Typography className="mobile-app-text body-fonts">Automate your whole hotel activites through our management tool.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Hotel_management} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">Managing the flow of customers at hotels and restaurant, while simultaneously managing the internal workings of it has been quite strenuous for the managers. We have created a system, that will automate most of these works. You can just focus on your business growth and developments.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>  
                </Box>
            </Container>
        </Box>
    )
}
export default Hotel;