import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import Web_app from "../../static/image/web_app.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React_native from '../../static/image/logo192.png';
import JS from "../../static/image/js.png";
import Html5 from "../../static/image/html-5.png";
import TypeScript from "../../static/image/typescript.png";
import Angular from "../../static/image/angular.png";
import Css3 from "../../static/image/css-3.png";
import Python from "../../static/image/python.png";
import Node_js from "../../static/image/nodejs.png";
import Postgre from "../../static/image/postgre.png";
import Mongo from "../../static/image/mongodb.svg";
import API from "../../static/image/api.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";

const Web_development = ()=>{
    return(
        <Box>
            <Header/>
            <Web_development_page/>
            <Footer/>
        </Box>
    )
}

const Web_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={3} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid item md={5} xs={12}>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development11 header-fonts">Web App<span className="mobile-app-development1"> Development</span></Typography>
                            <Typography className="mobile-app-text body-fonts">Every company at present requires a web-based application for automation of their business processes. Be it a small static website or full-fledged web application, we have been serving all types of client, according to their needs.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item xs={7} className="res-display">
                        <img src={Web_app} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">Technology That We Use on Web Development</Typography>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin12 header-fonts">Frontend Development</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={React_native} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">React JS</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={TypeScript} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Type Script</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Angular} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Angular JS</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={JS} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Java Script</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Html5} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Html5</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Css3} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Css3</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{textAlign:'left'}}>
                                <Typography variant="h3" className="fs-1 my-client-margin header-fonts">Backend Development</Typography>
                            </Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Python} className="new-image-width" alt="python-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Python (Django)</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4}  style={{margin:'0 auto'}}>
                                                <img src={Node_js} className="new-image-width" alt="node-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Node JS (Express Js)</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Postgre} className="new-image-width" alt="postgre-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">PostgreSQL</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Mongo} className="new-image-width" alt="mongo-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Mongo DB</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={API} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts" >Rest API</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Web_development;