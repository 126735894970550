import { Box, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import Block_chain from "../../static/image/block-chain.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Requirement from "../../static/image/checklists.png";
import UI_UX from "../../static/image/web-design.png";
import Prototype from "../../static/image/prototype.png";
import Web_development1 from "../../static/image/web-development.png";
import Quality from "../../static/image/quality-assurance.png";
import Deployment from "../../static/image/deployment.png";
import Technical from "../../static/image/technical-support.png";
import Application from "../../static/image/application.png";
import Smart_contract from "../../static/image/contract.png";
import Team from "../../static/image/team.png";
import Payment from "../../static/image/payment-gateway.png";
import Logistics from "../../static/image/logistics.png";
import Montoring from "../../static/image/monitoring.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Step_component from "../component/stepComponent";
import { useNavigate } from "react-router-dom";


const Block_development = ()=>{
    return(
        <Box>
            <Header/>
            <Block_development_page/>
            <Footer/>
        </Box>
    )
}

const Block_development_page = ()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const history = useNavigate();

      const Career = ()=>{
          history('/career')
      }
    return(
        <Box>
            <Container>
                <Grid container spacing={2} className="new-transition-effect" data-aos="fade-up"
                                        data-aos-offset="200"
                                        data-aos-delay="50"
                                        data-aos-duration="1000"
                                        data-aos-easing="ease-in-out"
                                        data-aos-mirror="true"
                                        data-aos-once="false"
                                        data-aos-anchor-placement="top-center">
                    <Grid md={5} xs={12} item>
                        <Box className="slider-text-margin">
                            <Typography className="mobile-app-development header-fonts">Block Chain<span className="mobile-app-development1"> Development</span></Typography>
                            <Typography className="mobile-app-text body-fonts">With the growing cyber security issues and lack of trust among the business stakeholders, digitalizing some of the business domains have frighteningly plateaued. We provide enterprise blockchain solution to such business need, so that they can automate their business activities, without any wariness.</Typography>
                            <button className="get-a-quote-button body-fonts" onClick={Career}>Get A Quote <FontAwesomeIcon icon={faArrowRight} className="get-a-quote-button1"/></button>
                        </Box>
                    </Grid>
                    <Grid item md={7} className="res-display">
                        <img src={Block_chain} alt="mobile-app-development" className="new-image-width" />
                    </Grid>
                </Grid>
                <Box className="my-client-margin">
                    <Box>
                        <Typography className="web-design-css body-fonts">The smartphone Apps have changed the definition of every day activity in human life. It has been an essential part of our lives and why not? We do almost everything with the help of an app. The world is connected with mobile apps. In this really techie era, Hyperlink Infosystem can help you to craft some excellent mobile application for your business & start-ups. We are the leading tailor made mobile app development company, serving finest apps across the globe.</Typography>
                        <Typography className="web-design-css body-fonts">We have top mobile app developers who are highly skilled and updated with the latest technology trend. Our team works with the global firms to transform recognized ideas into stunning & inventive mobile Apps. We have rich experience and creativity in producing stunning mobile apps for different clients over the world.</Typography>
                    </Box>
                    <Box>
                        <Step_component/>
                    </Box>
                    <Box className="my-client-margin">
                        <Box className="new-body-text">
                            <Typography variant="h1" className="fs-2 fw-bolder header-fonts">What We Can Provide</Typography>
                        </Box>
                        <Box>
                            <Box className="my-client-margin12">
                                <Grid container spacing={3}>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Application} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Decentralized Application</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Smart_contract} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Smart Contract</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Team} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Enterprise Solution</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Payment} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Micro Payment Solution</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Logistics} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Logistics Solution</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} xs={4} style={{textAlign:'start'}}>
                                        <Box className="web-design-services-card1">
                                            <Grid item xs={4} style={{margin:'0 auto'}}>
                                                <img src={Montoring} className="new-image-width" alt="react-image"/>
                                            </Grid>
                                            <Typography className="web-design-css1 body-fonts">Compliance System</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>    
                </Box>
            </Container>
        </Box>
    )
}
export default Block_development;